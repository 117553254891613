<template>
  <dashboard-layout>
    <template #content>
      <!-- page header component -->
      <page-header
        name="search"
        icon="search"
      />

      <section
        v-if="!isAdvancedSearchActive"
        class="section"
      >
        <div class="container">
          <b-field grouped>
            <b-autocomplete
              v-model="searchClause"
              icon-right="search"
              field="title"
              :open-on-focus="true"
              :data="searchResults"
              :loading="isLoading"
              max-height="2000px"
              expanded
              @typing="getAsyncData"
              @select="(option) => (selected = option)"
            >
              <template slot-scope="props">
                <div
                  v-for="(searchResult, index) in props"
                  :key="index"
                >
                  <template v-for="(category, categoryIndex) in Object.keys(searchResult)">
                    <div
                      v-if="
                        Object.values(searchResult)[categoryIndex] &&
                          Object.values(searchResult)[categoryIndex].length > 0
                      "
                      :key="categoryIndex"
                      class="columns"
                    >
                      <div class="column is-one-fifth has-background-light">
                        <div class="content has-text-right">
                          <p class="has-text-primary has-text-weight-semibold is-capitalized">
                            {{ category }}
                          </p>
                          <span class="has-text-grey">
                            <template v-if="Object.values(searchResult)[categoryIndex].length >= 3">
                              3
                            </template>
                            <template v-else>
                              {{ Object.values(searchResult)[categoryIndex].length }}
                            </template>

                            of
                            {{ Object.values(searchResult)[categoryIndex].length }}
                            results
                          </span>
                        </div>
                      </div>

                      <div class="column">
                        <template
                          v-for="(result, resultIndex) in Object.values(searchResult)[
                            categoryIndex
                          ]"
                        >
                          <div
                            v-if="resultIndex < 3"
                            :key="resultIndex"
                          >
                            <template v-if="category === 'users'">
                              <div class="media">
                                <figure class="media-left is-32x32">
                                  <img
                                    style="width: 32px; height: 32px;"
                                    class="is-rounded"
                                    :src="result.userPhoto ? result.userPhoto : defaultImage"
                                    :alt="result.firstName"
                                  >
                                </figure>
                                <div class="media-content">
                                  <router-link
                                    class="has-text-info has-text-weight-semibold is-capitalized"
                                    :to="{
                                      name: 'EditUser',
                                      params: { userId: result.userId },
                                    }"
                                  >
                                    {{ `${result.firstName} ${result.lastName}` }}
                                  </router-link>
                                </div>
                              </div>
                            </template>

                            <template v-if="category === 'contacts'">
                              <div class="media">
                                <figure class="media-left is-32x32">
                                  <img
                                    style="width: 32px; height: 32px;"
                                    class="is-rounded"
                                    :src="result.photo ? result.photo : defaultImage"
                                    :alt="result.firstName"
                                  >
                                </figure>
                                <div class="media-content">
                                  <router-link
                                    class="has-text-info has-text-weight-semibold is-capitalized"
                                    :to="{
                                      name: 'ViewContact',
                                      params: { contactId: result.contactId },
                                    }"
                                  >
                                    {{ `${result.firstName} ${result.lastName}` }}
                                  </router-link>
                                </div>
                              </div>
                            </template>

                            <template v-if="category === 'activities'">
                              <strong class="has-text-info has-text-weight-semibold is-capitalized">
                                <router-link
                                  :to="{
                                    name: 'EditActivity',
                                    params: { activityId: result.activityId },
                                  }"
                                >
                                  {{ result.activityName }}
                                </router-link>
                              </strong>
                            </template>
                            <template v-else>
                              <strong class="has-text-info has-text-weight-semibold is-capitalized">
                                {{ result.name }}
                              </strong>
                            </template>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </template>

              <template slot="footer">
                <b-button
                  type="is-primary"
                  @click="isAllResultsActive = !isAllResultsActive"
                >
                  view results
                </b-button>
              </template>

              <template slot="empty">
                No results for {{ searchClause }}
              </template>
            </b-autocomplete>

            <div class="control">
              <b-button
                type="is-info"
                :icon-left="isAdvancedSearchActive ? 'chevron-up' : 'chevron-down'"
                @click="isAdvancedSearchActive = !isAdvancedSearchActive"
              >
                advanced search
              </b-button>
            </div>
          </b-field>

          <template v-if="isAllResultsActive && searchResults.length > 0">
            <div
              v-for="(item, index) in Object.keys(searchResults[0])"
              :key="index"
            >
              <template
                v-if="
                  Object.values(searchResults[0])[index] &&
                    Object.values(searchResults[0])[index].length > 0
                "
              >
                <div class="card">
                  <header class="card-header has-background-primary">
                    <p class="card-header-title has-text-white">
                      {{ item }} : &nbsp;
                      <i class="has-text-weight-light">
                        {{
                          searchResults[0][item].length > 1
                            ? `${searchResults[0][item].length} results`
                            : `${searchResults[0][item].length} result`
                        }}
                      </i>
                    </p>
                  </header>

                  <div class="card-content">
                    <accounts-table
                      v-if="item === 'accounts'"
                      :accounts="searchResults[0][item]"
                    />

                    <users-table
                      v-if="item === 'users'"
                      :users="searchResults[0][item]"
                    />

                    <contacts-table
                      v-if="item === 'contacts'"
                      :contacts="searchResults[0][item]"
                    />
                    <leads-table
                      v-if="item === 'leads'"
                      :leads="searchResults[0][item]"
                    />
                    <opportunities-table
                      v-if="item === 'opportunities'"
                      :opportunities="searchResults[0][item]"
                    />
                    <documents-table
                      v-if="item === 'documents'"
                      :documents="searchResults[0][item]"
                    />
                    <activities-table
                      v-if="item === 'activities'"
                      :activities="searchResults[0][item]"
                    />
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </section>

      <section
        v-else
        class="section"
      >
        <div class="container">
          <template>
            <!-- loading component -->
            <b-loading
              v-model="isLoading"
              :is-full-page="false"
            />

            <div class="content">
              <h3 class="subtitle has-text-info is-capitalized">
                advanced search
              </h3>
            </div>

            <div class="media">
              <div class="media-left">
                <search-sidebar />
              </div>

              <div class="media-content">
                <search-advanced-form />
                <br>
                <b-field>
                  <b-button
                    type="is-info"
                    icon-left="search"
                    @click="search"
                  >
                    search
                  </b-button>
                </b-field>
              </div>
            </div>
            <br>
          </template>

          <template v-if="isAllResultsActive && searchResults.length > 0">
            <div
              v-for="(item, index) in Object.keys(searchResults[0])"
              :key="index"
            >
              <template
                v-if="
                  Object.values(searchResults[0])[index] &&
                    Object.values(searchResults[0])[index].length > 0
                "
              >
                <div class="card">
                  <header class="card-header has-background-primary">
                    <p class="card-header-title has-text-white">
                      {{ item }} : &nbsp;
                      <i class="has-text-weight-light">
                        {{
                          searchResults[0][item].length > 1
                            ? `${searchResults[0][item].length} results`
                            : `${searchResults[0][item].length} result`
                        }}
                      </i>
                    </p>
                  </header>

                  <div class="card-content">
                    <accounts-table
                      v-if="item === 'accounts'"
                      :accounts="searchResults[0][item]"
                    />

                    <users-table
                      v-if="item === 'users'"
                      :users="searchResults[0][item]"
                    />

                    <contacts-table
                      v-if="item === 'contacts'"
                      :contacts="searchResults[0][item]"
                    />
                    <leads-table
                      v-if="item === 'leads'"
                      :leads="searchResults[0][item]"
                    />
                    <opportunities-table
                      v-if="item === 'opportunities'"
                      :opportunities="searchResults[0][item]"
                    />
                    <documents-table
                      v-if="item === 'documents'"
                      :documents="searchResults[0][item]"
                    />
                    <activities-table
                      v-if="item === 'activities'"
                      :activities="searchResults[0][item]"
                    />
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </section>
    </template>
  </dashboard-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  SearchAdvancedForm,
  SearchSidebar,
  AccountsTable,
  ContactsTable,
  LeadsTable,
  OpportunitiesTable,
  DocumentsTable,
  ActivitiesTable,
  UsersTable,
} from '@/components/Search';
import { PageHeader } from '@/components/Shared';

import defaultImage from '@/assets/images/avatar.svg';

export default {
  name: 'Search',

  components: {
    DashboardLayout: () => import('../layouts/DashboardLayout.vue'),
    SearchAdvancedForm,
    SearchSidebar,
    AccountsTable,
    ContactsTable,
    LeadsTable,
    OpportunitiesTable,
    DocumentsTable,
    UsersTable,
    ActivitiesTable,
    PageHeader,
  },

  data: () => ({
    isAdvancedSearchActive: false,
    isAllResultsActive: true,
    defaultImage,
  }),

  computed: {
    ...mapGetters({
      isLoading: 'Search/getLoading',
      searchResults: 'Search/getSearchResults',
      advancedSearchParams: 'Search/getAdvancedSearchParams',
    }),

    searchClause: {
      get() {
        return this.$store.state.Search.searchClause;
      },
      set(value) {
        return this.$store.commit('Search/SET_SEARCH_CLAUSE', value);
      },
    },
  },

  methods: {
    getAsyncData() {
      this.$store.dispatch('Search/search', this.searchClause);
      setTimeout(function () {
        this.getAsyncData();
      }, 5000);
    },

    search() {
      this.$store.dispatch('Search/advancedSearch', {
        clause: this.searchClause,
        advanced: this.advancedSearchParams,
      });
      this.isAllResultsActive = true;
    },
  },
};
</script>

<style scoped></style>
